<template>
	<div class="content" :style="cssVars">
		<vue-headful
			:title="pageTitle"/>
		
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		
		<main v-if="!isLoading">
			
			<header>
				<h1>{{ hall.name }}</h1>
			</header>
			
			<div v-if="hall" class="artworks-grid">
				<div v-for="artwork in hall.artworkImages"
				     :key="artwork.idArtwork"
				     class="artwork"
				     @click="goToArtwork(artwork.idArtwork)">
					<img :src="artwork.mainImageUrl" alt="" class="artwork-image">
					<div v-if="artwork.mainAudioUrl" class="sound-icon">
						<i class="mm-icon mm-icon-audio white"/>
					</div>
				</div>
			</div>
		</main>
		
		<footer>
			<button class="button-white" @click="goBack()">
				<i class="mm-icon mm-icon-close big"/>
			</button>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import { mapActions, mapState } from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: "Hall",
	
	components: {
		Loading,
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			idHall: this.$route.params.idHall || "0"
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			hall: state => state.hall.hall,
			hallError: state => state.hall.hallError,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('hall');
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			return locationName + ' | ' + museumName;
		},
		
		cssVars() {
			return {
				'--main-buttons-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsColor : '#72c4d0',
				'--main-buttons-text-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsTextColor : '#ffffff',
			};
		}
	},
	
	created() {
		debug.log("[Hall] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		this.$store.commit('app/' + mutationTypes.SET_VIEW_FROM, { name: 'Hall', params: { idMuseum: this.encrypted, idHall: this.idHall } });
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.then(() => {
					this.getHall(this.idHall);
				})
				.finally(() => {
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('hall', ['getHall']),
		
		goBack() {
			this.$router.go(-1);
		},
		
		goToArtwork(idArtwork) {
			this.$store.commit('app/' + mutationTypes.SET_VIEW_FROM, {
				name: 'Beacon',
				params: { idMuseum: this.encrypted, idHall: this.idHall}
			});
			
			this.$router.push({ name: 'Artwork', params: { idMuseum: this.encrypted, idArtwork: idArtwork } });
		}
	},
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.content {
	min-height: 100vh;
	
	header {
		position: fixed;
		top: 0;
		height: 70px;
		width: 100%;
		background-color: $green;
		padding: 20px;
		z-index: 1;
		
		h1 {
			color: white;
			font-size: 24px;
			font-weight: normal;
		}
	}
	
	main {
		width: 100%;
		height: 100vh;
		padding: 90px 0 180px;
		display: flex;
		flex-direction: column;
		overflow-y: scroll;
		
		.artworks-grid {
			line-height: 0;
			column-count: 2;
			column-gap: 30px;
			padding: 0 10px;
			
			.artwork {
				position: relative;
				margin-bottom: 20px;
				box-shadow: 0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
				
				.artwork-image {
					width: 100%;
					height: auto;
				}
				
				.sound-icon {
					position: absolute;
					bottom: 5px;
					right: 5px;
					background-color: var(--main-buttons-color);
					width: 30px;
					height: 30px;
					border-radius: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					
					.mm-icon {
						color: var(--main-buttons-text-color);
					}
				}
			}
		}
	}
	
	footer {
		height: 90px;
		width: 100%;
		display: flex;
		justify-content: center;
		justify-items: center;
		max-width: 100vw;
		position: fixed;
		bottom: 0;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		
		button {
			width: 100%;
			border: none;
			text-transform: uppercase;
			
			&.button-white {
				background-color: white;
				color: black;
			}
			
		}
	}
}
</style>
